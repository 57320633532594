<template>
  <multiselect class="sally-multi-select"
    :class="{'multiple': multiple, 'searchable': searchable, 'group-select': groupSelect}"
    v-model="visualValue"
    :options="options"
    :track-by="trackBy"
    :label="label"
    :close-on-select="!multiple"
    :multiple="multiple"
    :allow-empty="multiple"
    :searchable="searchable"
    :show-no-results="false"
    :max-height="maxHeight"
    :preserveSearch="searchable"
    :show-labels="false"
    :preselect-first="preselectFirst"
    :placeholder="placeHolder"
    :disabled="disabled"
    :group-values="groupValues"
    :group-label="groupLabel"
    :group-select="groupSelect"
    :open-direction="openDirection"
    @input="(value, id) => $emit('input', value, id)"
    @remove="(removedOption, id) => $emit('remove', removedOption, id)"
    @select="(selectedOption, id) => $emit('select', selectedOption, id)"
    @search-change="(searchQuery, id) => $emit('search-change', searchQuery, id)">
    <template slot="caret">
      <div class="multiselect-arrow">
        <img src="~@/assets/images/expand.svg"/>
      </div>
    </template>
    <template slot="selection" slot-scope="{ values }">
      <span class="multiselect__single" v-if="values.length">
        <span v-if="values[0][trackBy] === 0">
          {{values[0][label] ? values[0][label] : $t('base_select.not_selected')}}
        </span>
        <span v-else-if="values.length === 1">{{ values[0][label] }}</span>
        <span v-else>{{ values[0][label] }} {{ $t('base_select.selected_item', { item: values.length - 1}) }}</span>
        <slot name="count-badge" :values="values"/>
      </span>
    </template>
    <template v-if="multiple && !groupSelect" slot="option" slot-scope="props">
      <div class="option">
        <div class="option__title">{{ props.option[label] }}</div>
        <img src="~@/assets/images/selectbox-check.svg" />
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: [Object, Array, String],
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    trackBy: {
      type: String,
    },
    label: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      },
    },
    searchable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    maxHeight: {
      type: Number,
      default() {
        return 200;
      },
    },
    preselectFirst: {
      type: Boolean,
      default() {
        return true;
      },
    },
    placeHolder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    groupLabel: {
      type: String,
    },
    groupValues: {
      type: String,
    },
    groupSelect: {
      type: Boolean,
    },
    openDirection: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      visualValue: this.value,
    };
  },
  watch: {
    value() {
      this.visualValue = this.value;
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/style/variable.scss';
@import '~@/assets/style/components/sally-multiselect.scss';
.sally-multi-select.multiselect {
  .multiselect__tags:hover {
    border-color: #00ca94;
    cursor: pointer;
  }
  .multiselect-arrow {
    position: absolute;
    right: 0;
    min-height: 32px;
    display: flex;
    align-self: center;
    padding: 0 10px;
    img {
      width: 10px;
    }
  }
  .multiselect__content-wrapper {
    right: 1px;
    left: auto;
    ul.multiselect__content {
      li.multiselect__element {
        .multiselect__option {
          &:hover {
            background-color: rgba(0, 202, 148, 0.05);
            color: #00ca94;
            font-weight: 500;
          }
          .option {
            width: 100%;
            display: flex;
            flex: 1;
            justify-content: space-between;
            position: relative;
            .option__title {
              max-width: 100%;
              overflow:hidden;
              white-space:nowrap;
              text-overflow:ellipsis;
            }
            img {
              right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
