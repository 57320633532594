<template>
  <!-- eslint-disable -->
  <div class="privacy-and-policy">
    <div class="scroll">
      <div class="header">
        <img src="~@/assets/images/sally-logo-full.svg"/>
      </div>
      <div class="title">{{ $t('privacy_policy') }}</div>
      <div class="detail-container">
        <div class="detail">
          <div class="select-btn">
            <div class="btn-name">{{ $t('privacy.version') }}</div>
            <sally-multi-select
              class="select-box"
              track-by="id"
              label="text"
              @select="selectedOption => select(selectedOption)"
              v-model="ownerFilter"
              :options="options"/>
          </div>
          <div v-html="$sanitize(selected, sanitizeOptions)"/>
        </div>
      </div>
      <div class="footer">
        <Copyright/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SallyMultiSelect from '@/components/Common/SallyMultiSelect.vue';
import Copyright from '@/components/Common/Copyright.vue';
import { format } from '@/common/date.util';

export default {
  name: 'privacyandpolicy',
  data() {
    return {
      data: [],
      result: [],
      options: [],
      selected: [],
      ownerFilter: [],
      sanitizeOptions: {
        allowedTags: ['div', 'pre'],
        allowedAttributes: {
          div: ['class'],
          pre: ['class'],
        },
      },
    };
  },
  components: {
    SallyMultiSelect,
    Copyright,
  },
  created() {
    this.getHtmlContents();
  },
  methods: {
    ...mapActions(['getPrivacyAndPolicy']),
    select(selectedOption) {
      // this.selected = this.data.filter(p => p.version === selectedOption.text)[0].content;
      this.selected = this.data.filter(p => selectedOption.text.includes(p.version))[0].content;
    },
    async getHtmlContents() {
      this.data = await this.getPrivacyAndPolicy();
      this.options = this.data
        .map((d, index) => {
          const extra = (index === 0) ? '(최신 버전)' : '';
          return { id: d.id, text: `${d.version} ${format(d.date)} ${extra}` };
        });
      this.ownerFilter = this.options[0];
      this.selected = this.data[0].content;
    },
    compareVersion(a, b) {
      if (a.version < b.version) {
        return 1;
      }
      if (a.version > b.version) {
        return -1;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/style/variable.scss';

.privacy-and-policy {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .scroll {
    overflow: auto;

    .header {
      height: 52px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        height: 32px;
      }
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 180px;
      background-color: #f4f4f6;
      font-size: $font_size_36;
      font-weight: 600;
      letter-spacing: normal;
      text-align: left;
      color: #2a324b;
    }

    .detail-container {
      display: flex;
      justify-content: center;

      .detail {
        display: flex;
        flex-direction: column;
        margin-bottom: 98px;
        max-width: $max_width;
        margin-left: $standard_margin_size;
        margin-right: $standard_margin_size;
        width: 100%;

        .select-btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 96px;

          .btn-name {
            width: 23px;
            height: 15px;
            margin: 9px 5px 8px 0;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.42;
            letter-spacing: normal;
            text-align: right;
            color: #2a324b;
          }

          .select-box {
            width: 220px;
            margin-right: 18px;
          }
        }

        .title2 {
          font-size: $font_size_24;
          font-weight: 600;
          letter-spacing: normal;
          color: #2a324b;
          margin-top: 97px;
          margin-bottom: 19px;
        }

        .title3 {
          font-size: $font_size_18;
          letter-spacing: normal;
          color: #2a324b;
          font-weight: bold;
          margin-top: 19px;
        }

        .title4 {
          font-size: $font_size_18;
          letter-spacing: normal;
          color: #2a324b;
          margin-top: 48px;
          //margin-bottom: 48px;
        }

        .contents {
          font-size: $font_size_18;
          letter-spacing: normal;
          color: #2a324b;
          line-height: 1.33;
          white-space: pre-wrap;
          margin: 0;
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 31px;
      background-color: #f7f8fa;
      font-size: $font_size_12;
      font-weight: $font_weight_400;
      letter-spacing: normal;
      color: #acacac;
    }
  }
}
</style>
